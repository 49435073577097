import Vue from 'vue'
import { API, Storage } from 'aws-amplify'
import * as queries from '@/src/graphql/queries'
import * as mutations from '@/src/graphql/mutations'
import * as subscriptions from '@/src/graphql/subscriptions'

class ApiService {
  constructor (ctx) {
    this.ctx = ctx
    this.subscriptions = {
      onPostEvent: []
    }
  }

  _getFilteredData (data, re) {
    return Object.keys(data).reduce((acc, cur) => {
      if (cur.match(re)) {
        acc[cur] = data[cur]
      }
      return acc
    }, {})
  }

  fakeApiCall (delay) {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, delay || 1000)
    })
  }

  async getAvailableGroups () {
    try {
      const groups = await API.graphql({
        query: queries.getAvailableGroups
      })
      return {
        items: groups.data.getAvailableGroups
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchGroups ({ from, size, query, sort }) {
    try {
      const groups = await API.graphql({
        query: queries.searchGroups,
        variables: {
          from, size, query, sort
        }
      })
      return groups.data.searchGroups
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getUsers () {
    try {
      const users = await API.graphql({
        query: queries.getUsers
      })
      return {
        items: users.data.getUsers
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchUsers ({ from, size, query, sort }) {
    try {
      const users = await API.graphql({
        query: queries.searchUsers,
        variables: {
          from, size, query, sort
        }
      })
      return users.data.searchUsers
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async userEmailExists (email) {
    try {
      const users = await API.graphql({
        query: queries.userEmailExists,
        variables: {
          email
        }
      })
      return users.data.userEmailExists
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createUser (data) {
    try {
      const fields = ['email', 'domains', 'custom__domains', 'phone', 'suppressMsg', 'groups', 'custom__lookup_codes']
      return await API.graphql({
        query: mutations.createUser,
        variables: this._getFilteredData(data, new RegExp(`^(${fields.join('|')})$`))
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateUser (data) {
    try {
      const fields = ['username', 'email', 'domains', 'custom__domains', 'phone', 'groups', 'custom__lookup_codes']
      return await API.graphql({
        query: mutations.updateUser,
        variables: this._getFilteredData(data, new RegExp(`^(${fields.join('|')})$`))
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteUser (username) {
    try {
      return await API.graphql({
        query: mutations.deleteUser,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async disableUser (username) {
    try {
      return await API.graphql({
        query: mutations.disableUser,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async enableUser (username) {
    try {
      return await API.graphql({
        query: mutations.enableUser,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async resetUserPassword (username) {
    try {
      return await API.graphql({
        query: mutations.resetUserPassword,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async resendTempPassword (username) {
    try {
      return await API.graphql({
        query: mutations.resendTempPassword,
        variables: { username }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async setLookupCodes (value, newData, callback) {
    try {
      // update current user, populate this.forms.user with current user info, update lookup_codes to value, call updateItem
      const me = {
        username: this.ctx.$auth.user.username,
        email: this.ctx.$auth.email,
        groups: this.ctx.$auth.groups,
        enabled: true, // must be true since they are performing the action
        custom__lookup_codes: this.ctx.$auth.user.attributes['custom:lookup_codes'],
        custom__domains: this.ctx.$auth.user.attributes['custom:domains'],
        phone: this.ctx.$auth.phoneNumber
      }
      const updateUser = await this.ctx.$api.updateUser({ ...newData, ...me, custom__lookup_codes: value })
      if (typeof callback === 'function') {
        callback(updateUser)
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getDomains () {
    try {
      const groupPermissions = await API.graphql({
        query: queries.getDomains
      })
      return {
        items: groupPermissions.data.getDomains
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchDomains ({ from, size, query, sort }) {
    try {
      const domains = await API.graphql({
        query: queries.searchDomains,
        variables: {
          from, size, query, sort
        }
      })
      return domains.data.searchDomains
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createDomain (data) {
    try {
      return await API.graphql({
        query: mutations.createDomain,
        variables: this._getFilteredData(data, /^(name|description)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateDomain (data) {
    try {
      return await API.graphql({
        query: mutations.updateDomain,
        variables: this._getFilteredData(data, /^(id|name|description)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteDomain (id) {
    try {
      return await API.graphql({
        query: mutations.deleteDomain,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchAnnouncements ({ from, size, query, sort }) {
    try {
      const announcements = await API.graphql({
        query: queries.searchAnnouncements,
        variables: {
          from, size, query, sort
        }
      })
      return announcements.data.searchAnnouncements
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createAnnouncement (data) {
    try {
      return await API.graphql({
        query: mutations.createAnnouncement,
        variables: this._getFilteredData(data, /^(message|order|enabled)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateAnnouncement (data) {
    try {
      return await API.graphql({
        query: mutations.updateAnnouncement,
        variables: this._getFilteredData(data, /^(id|message|order|enabled)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createFaq (data) {
    try {
      return await API.graphql({
        query: mutations.createFaq,
        variables: this._getFilteredData(data, /^(question|answer|category|order)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateFaq (data) {
    try {
      return await API.graphql({
        query: mutations.updateFaq,
        variables: this._getFilteredData(data, /^(id|question|answer|category|order)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteFaq (id) {
    try {
      return await API.graphql({
        query: mutations.deleteFaq,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getFaqs () {
    try {
      const faqs = await API.graphql({
        query: queries.getFaqs
      })
      return {
        items: faqs.data.getFaqs
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchFaqs ({ from, size, query, sort }) {
    try {
      const faqs = await API.graphql({
        query: queries.searchFaqs,
        variables: {
          from, size, query, sort
        }
      })
      return faqs.data.searchFaqs
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createLibraryLink (data) {
    try {
      return await API.graphql({
        query: mutations.createLibraryLink,
        variables: this._getFilteredData(data, /^(linkId|libraryKey)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateLibraryLink (data) {
    try {
      return await API.graphql({
        query: mutations.updateLibraryLink,
        variables: this._getFilteredData(data, /^(id|linkId|libraryKey)$/)
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteLibraryLink (id) {
    try {
      return await API.graphql({
        query: mutations.deleteLibraryLink,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getLibraryLinks () {
    try {
      const libraryLinks = await API.graphql({
        query: queries.getLibraryLinks
      })
      return {
        items: libraryLinks.data.getLibraryLinks
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async searchLibraryLinks ({ from, size, query, sort }) {
    try {
      const libraryLinks = await API.graphql({
        query: queries.searchLibraryLinks,
        variables: {
          from, size, query, sort
        }
      })
      return libraryLinks.data.searchLibraryLinks
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async forgetDevice (deviceKey) {
    try {
      return await API.graphql({
        query: mutations.forgetDevice,
        variables: {
          deviceKey
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  forgetAllDevices (currentDeviceKey) {
    const that = this
    try {
      this.ctx.$auth.user.listDevices(null, null, {
        async onSuccess (result) {
          const promises = result.Devices.reduce((acc, cur) => {
            if (cur.DeviceKey === currentDeviceKey) {
              acc.push(that.ctx.$auth.user.setDeviceStatusNotRemembered({ onSuccess () {}, onFailure () {} }))
            } else {
              acc.push(that.ctx.$api.forgetDevice(cur.DeviceKey))
            }
            return acc
          }, [])
          await Promise.all(promises)
        },
        onFailure () {}
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getFileList (apigw) {
    try {
      if (apigw) {
        const res = await API.get(this.ctx.$awsConfig.aws_cloud_logic_custom[0].name, '/files', {
          headers: {
            Authorization: `Bearer ${this.ctx.$auth.user.signInUserSession.idToken.jwtToken}`,
            'X-Amz-Access-Token': this.ctx.$auth.user.signInUserSession.accessToken.jwtToken
          }
        })
        if (res) {
          return {
            data: {
              getFileList: res
            }
          }
        }
        throw new Error(res)
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getFile ({ policyId, attachmentId }, apigw) {
    try {
      if (apigw) {
        const res = await API.get(this.ctx.$awsConfig.aws_cloud_logic_custom[0].name, `/files/${policyId}/${attachmentId}`, {
          headers: {
            Authorization: `Bearer ${this.ctx.$auth.user.signInUserSession.idToken.jwtToken}`,
            'X-Amz-Access-Token': this.ctx.$auth.user.signInUserSession.accessToken.jwtToken
          }
        })
        if (res) {
          return {
            ...res,
            name: res.attachmentId
          }
        }
        throw new Error(res)
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async putFile ({ policyId, content, name, description, attachedToType }, apigw) {
    try {
      if (apigw) {
        const res = await API.put(this.ctx.$awsConfig.aws_cloud_logic_custom[0].name, `/files/${policyId}`, {
          headers: {
            Authorization: `Bearer ${this.ctx.$auth.user.signInUserSession.idToken.jwtToken}`,
            'X-Amz-Access-Token': this.ctx.$auth.user.signInUserSession.accessToken.jwtToken
          },
          body: { content, name, description, attachedToType }
        })
        if (res) {
          return {
            data: {
              putFile: res
            }
          }
        }
        throw new Error(res)
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storagePut ({ key, file, options }) {
    try {
      await Storage.put(key, file, options)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storageRemove ({ key }) {
    try {
      await Storage.remove(key)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storageList (prefix) {
    try {
      const res = await Storage.list(prefix || '')
      for (const i of res.results) {
        i.url = await Storage.get(i.key)
      }
      return res.results
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async storageGet (key, download = false, level = 'public', identityId = false) {
    try {
      return await Storage.get(key, {
        download,
        identityId,
        level
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async sendWalletPass ({ lookupCode, policyId, policyCode }) {
    const res = await API.graphql({
      query: mutations.sendWalletPass,
      variables: {
        lookupCode,
        policyId,
        policyCode
      }
    })
    return JSON.parse(res.data.sendWalletPass)
  }

  async getCompanyCodes () {
    const res = await API.graphql({
      query: queries.getCompanyCodes
    })
    return res.data.getCompanyCodes
  }

  async getMyProofOfInsuranceStatus () {
    const res = await API.graphql({
      query: queries.getMyProofOfInsuranceStatus
    })
    return JSON.parse(res.data.getMyProofOfInsuranceStatus)
  }

  async getApplicant (lookupCode, policyType, uniqPolicy) {
    const res = await API.graphql({
      query: queries.getApplicant,
      variables: {
        lookupCode,
        policyType,
        uniqPolicy
      }
    })
    return res.data.getApplicant
  }

  async getRatesbotOffers (lookupCode) {
    try {
      const variables = {
        lookupCode
      }

      const res = await API.graphql({
        query: queries.getRatesbotOffers,
        variables
      })
      return res.data.getRatesbotOffers
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getProofOfInsurance ({ lookupCode, policyId, policyCode }, apigw) {
    try {
      if (apigw) {
        const res = await API.get(this.ctx.$awsConfig.aws_cloud_logic_custom[0].name, `/files/${lookupCode}/${policyId}/${policyCode}`, {
          headers: {
            Authorization: `Bearer ${this.ctx.$auth.user.signInUserSession.idToken.jwtToken}`,
            'X-Amz-Access-Token': this.ctx.$auth.user.signInUserSession.accessToken.jwtToken
          }
        })
        if (res) {
          return res
        }
        throw new Error(res)
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPolicies () {
    try {
      const res = await API.graphql({
        query: queries.getPolicies
      })
      if (res && res.data && res.data.getPolicies) {
        return JSON.parse(res.data.getPolicies)
      }
      throw new Error(res)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPoliciesByPhone ({ phoneNumber }) {
    try {
      const res = await API.graphql({
        query: queries.getPoliciesByPhone,
        variables: {
          phoneNumber
        }
      })
      return JSON.parse(res.data.getPoliciesByPhone)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getCoverages ({ lookupCode, policyId, policyType }) {
    try {
      const res = await API.graphql({
        query: queries.getCoverages,
        variables: {
          lookupCode,
          policyId,
          policyType
        }
      })
      return JSON.parse(res.data.getCoverages)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  formattedVehicleCoverages ({ coverages, vehicleNum }) {
    const formattedCoverages = { additionalCoverages: [], coverages: [] }
    try {
      // coverages with these codes are coming in response.coverages instead of response.additional_coverages
      // so adding additional logic here to display these under additional coverages section
      const additionalCoveragesCodes = ['AP', 'COL', 'CMP', 'SP', 'COLOD']
      if (coverages?.coverages) {
        coverages.coverages
          .filter(coverage => coverage.description && coverage.vehicle_number === parseInt(vehicleNum))
          .forEach((coverage) => {
            if (additionalCoveragesCodes.includes(coverage.code)) {
              formattedCoverages.additionalCoverages.push(coverage)
            } else {
              formattedCoverages.coverages.push(coverage)
            }
          })
      }
      if (coverages?.additional_coverages) {
        const filteredAdditionalCoverages = coverages.additional_coverages.filter(
          coverage => coverage.description && coverage.vehicle_number === parseInt(vehicleNum)
        )
        formattedCoverages.additionalCoverages = [...formattedCoverages.additionalCoverages, ...filteredAdditionalCoverages]
      }
      if (coverages?.opcf) {
        const filteredOpcf = coverages.opcf.filter(
          coverage => coverage.description && coverage.vehicle_number === parseInt(vehicleNum)
        )
        formattedCoverages.additionalCoverages = [...formattedCoverages.additionalCoverages, ...filteredOpcf]
      }
    } catch (err) {
      console.log(err)
      throw this.errorHandler(err) || err
    }

    return formattedCoverages
  }

  async sendActOnEmail ({ formExt, emailData }) {
    try {
      const res = await API.graphql({
        query: mutations.sendActOnEmail,
        variables: {
          formExt,
          emailData
        }
      })
      if (res && res.data && res.data.sendActOnEmail && JSON.parse(res.data.sendActOnEmail).statusCode === 200) {
        return true
      } else {
        // TODO - Add custom error logging if email fails?
      }
      throw new Error(res)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async registerClientToAgency (status) {
    try {
      return await API.graphql({
        query: mutations.registerClientToAgency,
        variables: {
          status
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAvailableTimeSlots () {
    try {
      const res = await API.graphql({
        query: queries.getAvailableTimeSlots
      })
      return JSON.parse(res.data.getAvailableTimeSlots)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getScheduledCall () {
    try {
      const res = await API.graphql({
        query: queries.getScheduledCall
      })
      return JSON.parse(res.data.getScheduledCall)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createScheduledCall ({ clientName, clientEmail, clientCallbackStart, clientCallbackEnd, destinationPhoneNumber }) {
    try {
      const res = await API.graphql({
        query: mutations.createScheduledCall,
        variables: {
          clientName,
          clientEmail,
          clientCallbackStart,
          clientCallbackEnd,
          destinationPhoneNumber
        }
      })
      return JSON.parse(res.data.createScheduledCall)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateScheduledCall (id, { clientName, clientEmail, clientCallbackStart, clientCallbackEnd, destinationPhoneNumber, isDeletedByUser, uniqActivity }) {
    try {
      const variables = {
        id,
        clientName,
        clientEmail,
        clientCallbackStart,
        clientCallbackEnd,
        destinationPhoneNumber,
        isDeletedByUser,
        uniqActivity
      }
      if (isDeletedByUser) {
        variables.isDeletedByUser = isDeletedByUser
      }
      const res = await API.graphql({
        query: mutations.updateScheduledCall,
        variables
      })
      return JSON.parse(res.data.updateScheduledCall)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getProfile (lookupCode) {
    const variables = {}
    if (lookupCode) {
      variables.lookupCode = lookupCode
    }
    try {
      const res = await API.graphql({
        query: queries.getProfile,
        variables
      })
      return JSON.parse(res.data.getProfile)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateContact ({ phone, email }) {
    try {
      const variables = {}
      if (phone) {
        variables.phone = phone
      }
      if (email) {
        variables.email = email
      }
      const res = await API.graphql({
        query: mutations.updateContact,
        variables
      })
      return JSON.parse(res.data.updateContact)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async adminUpdateContact ({ lookupCode, phone, email }) {
    try {
      const variables = { lookupCode }
      if (phone) {
        variables.phone = phone
      }
      if (email) {
        variables.email = email
      }
      const res = await API.graphql({
        query: mutations.adminUpdateContact,
        variables
      })
      return JSON.parse(res.data.adminUpdateContact)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async requestInvitationLink ({ recaptchaToken, firstName, lastName, phone, email }) {
    try {
      const variables = {}

      variables.recaptchaToken = recaptchaToken
      variables.firstName = firstName
      variables.lastName = lastName
      variables.phone = phone
      variables.email = email

      const res = await API.graphql({
        query: mutations.requestInvitationLink,
        variables,
        authMode: 'AWS_IAM'
      })
      return JSON.parse(res.data.requestInvitationLink)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async sendEmail ({ emailType, emailData }, apigw) {
    try {
      await API.graphql({
        query: mutations.sendEmail,
        variables: {
          emailType,
          emailData
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  onPostEventSubscribe (scopeId) {
    const that = this
    const subscription = API.graphql({
      query: subscriptions.onPostEvent,
      variables: {
        scopeId
      }
    }).subscribe({
      async next ({ provider, value }) {
        // specific cognito user event
        if (value.data.onPostEvent.scopeId === that.ctx.store.state.auth.user.username) {
          if (value.data.onPostEvent.eventType === 'GLOBAL_SIGN_OUT') {
            window.location.reload()
          }
        } else if (value.data.onPostEvent.eventType === 'ANNOUNCEMENT_REFRESH') {
          try {
            await that.ctx.store.dispatch('announcements/setAnnouncements', true)
          } catch (err) {
            throw that.errorHandler(err) || err
          }
        } else if (value.data.onPostEvent.eventType === 'POLICIES_REFRESH') {
          if (that.ctx.$config.ENV !== 'prod') {
            // Send a notification in lower envs for easy testing
            that.ctx.store.dispatch('notification/showMessage', {
              message: 'Policies refresh event triggered, updating policies.',
              timeout: 4000
            })
          }
          that.ctx.store.dispatch('policies/setPolicies', true)
        }
      },
      error (err) {
        throw that.errorHandler(err) || err
      }
    })
    this.subscriptions.onPostEvent.push(subscription)
  }

  async sendFeedbackToEpic ({ score, feedback }) {
    try {
      const variables = {}

      variables.score = score
      variables.feedback = feedback

      const res = await API.graphql({
        query: mutations.sendFeedbackToEpic,
        variables
      })
      return JSON.parse(res.data.sendFeedbackToEpic)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTracking ({ query }) {
    try {
      return await API.graphql({
        query: queries.getTracking,
        variables: {
          query
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createTracking ({ page, action, lineOfBusiness, status, errorCode, email, payload }) {
    try {
      return await API.graphql({
        query: mutations.createTracking,
        variables: {
          page,
          action,
          lineOfBusiness: lineOfBusiness || 'N/A',
          status,
          errorCode,
          email,
          payload
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createTrackingFormstack (activityId, documentId, lookupCode) {
    try {
      return await API.graphql({
        query: mutations.createTrackingFormstack,
        variables: { activityId, documentId, lookupCode }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateTrackingFormstack (variables) {
    try {
      return await API.graphql({
        query: mutations.updateTrackingFormstack,
        variables: { ...variables }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateTrackingIcp ({ id, isDeleted }) {
    try {
      return await API.graphql({
        query: mutations.updateTrackingIcp,
        variables: { id, isDeleted }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPaymentDetails ({ policyNumber, fullName, postalCode, dateOfBirth }) {
    try {
      const res = await API.graphql({
        query: queries.getPaymentDetails,
        variables: {
          policyNumber,
          fullName,
          postalCode,
          dateOfBirth
        }
      })
      return JSON.parse(res.data.getPaymentDetails)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  getBillingDetails ({ policyNumber, company, signal }) {
    return new Promise((resolve, reject) => {
      const abortHandler = () => {
        API.cancel(promise, 'queries.getBillingDetails cancelled')
        reject(new DOMException('Aborted', 'AbortError'))
      }

      const promise = API.graphql({
        query: queries.getBillingDetails,
        variables: {
          policyNumber,
          company
        }
      }).then((res) => {
        resolve(JSON.parse(res.data.getBillingDetails))
        signal?.removeEventListener('abort', abortHandler)
      }).catch((err) => {
        reject(this.errorHandler(err) || err)
      })

      signal?.addEventListener('abort', abortHandler)

      return promise
    })
  }

  /**
   *
   * @param {String} transactionType
   * @param {String} variables
   * @returns response from Lambda
   */
  async paymentTransaction (transactionType, payload) {
    try {
      const res = await API.graphql({
        query: mutations[transactionType],
        variables: {
          payload
        }
      })
      return res
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createChangeRequest ({ uniqPolicy, payload }) {
    try {
      return await API.graphql({
        query: mutations.createChangeRequest,
        variables: {
          uniqPolicy,
          payload
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deleteChangeRequest (id) {
    try {
      return await API.graphql({
        query: mutations.deleteChangeRequest,
        variables: {
          id
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async updateChangeRequest ({ id, uniqPolicy, payload, isDeleted }) {
    try {
      return await API.graphql({
        query: mutations.updateChangeRequest,
        variables: {
          id,
          uniqPolicy,
          payload,
          isDeleted
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getChangeRequests () {
    try {
      const res = await API.graphql({
        query: queries.getChangeRequests
      })
      return res.data.getChangeRequests
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getChangeRequest ({ uniqPolicy }) {
    try {
      const res = await API.graphql({
        query: queries.getChangeRequest,
        variables: {
          uniqPolicy
        }
      })
      return res.data.getChangeRequest
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getChangeRequestById ({ id }) {
    try {
      const res = await API.graphql({
        query: queries.getChangeRequestById,
        variables: {
          id
        }
      })
      return res.data.getChangeRequestById
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async submitChangeRequest ({ uniqPolicy, lookupCode }) {
    try {
      return await API.graphql({
        query: mutations.submitChangeRequest,
        variables: {
          uniqPolicy,
          lookupCode
        }
      })
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getValidEffectiveDates ({ startDate, endDate }) {
    try {
      const res = await API.graphql({
        query: queries.getValidEffectiveDates,
        variables: {
          startDate, endDate
        }
      })
      return res.data.getValidEffectiveDates
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getActivity ({ uniqActivity }) {
    try {
      const res = await API.graphql({
        query: queries.getActivity,
        variables: {
          uniqActivity
        }
      })
      return res.data.getActivity
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getActivities ({ uniqPolicy, lookupCode }) {
    try {
      const res = await API.graphql({
        query: queries.getActivities,
        variables: {
          uniqPolicy, lookupCode
        }
      })
      return res.data.getActivities
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getActivitiesByCode (activityCode) {
    try {
      const res = await API.graphql({
        query: queries.getActivitiesByCode,
        variables: {
          activityCode
        }
      })
      return res.data.getActivitiesByCode
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getActivityNotes (uniqActivity) {
    try {
      const res = await API.graphql({
        query: queries.getActivityNotes,
        variables: {
          uniqActivity
        }
      })
      return res.data.getActivityNotes
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createActivity ({ uniqPolicy, lookupCode, activityCode, note, openStatus, closedStatus, closedReason }) {
    try {
      const res = await API.graphql({
        query: mutations.createActivity,
        variables: {
          uniqPolicy, lookupCode, activityCode, note, openStatus, closedStatus, closedReason
        }
      })
      return res.data.createActivity
    } catch (err) {
      console.error(`Activity creation failed due to ${err}`)
      throw this.errorHandler(err) || err
    }
  }

  async updateActivityEntity ({ lookupCode, activityId, payload }) {
    try {
      const res = await API.graphql({
        query: mutations.updateActivityEntity,
        variables: {
          lookupCode, activityId, payload
        }
      })
      return res.data.updateActivityEntity
    } catch (err) {
      console.error(`Activity update failed due to ${err}`)
      throw this.errorHandler(err) || err
    }
  }

  async createActivityEntityBase (type, { lookupCode, activityCode, note, openStatus, closedStatus, closedReason, whoOwnerEmail, followUpStartDate, followUpStartTime }) {
    try {
      const res = await API.graphql({
        query: mutations[type],
        variables: {
          lookupCode, activityCode, note, openStatus, closedStatus, closedReason, whoOwnerEmail, followUpStartDate, followUpStartTime
        }
      })
      return res.data[type]
    } catch (err) {
      console.error(`Activity creation failed due to ${err}`)
      throw this.errorHandler(err) || err
    }
  }

  async createActivityEntity ({ lookupCode, activityCode, note, openStatus, closedStatus, closedReason, whoOwnerEmail, followUpStartDate, followUpStartTime }) {
    return await this.createActivityEntityBase('createActivityEntity', { lookupCode, activityCode, note, openStatus, closedStatus, closedReason, whoOwnerEmail, followUpStartDate, followUpStartTime })
  }

  async createActivityEntityUser ({ lookupCode, activityCode, note, openStatus, closedStatus, closedReason, whoOwnerEmail, followUpStartDate, followUpStartTime }) {
    return await this.createActivityEntityBase('createActivityEntityUser', { lookupCode, activityCode, note, openStatus, closedStatus, closedReason, whoOwnerEmail, followUpStartDate, followUpStartTime })
  }

  async changeRequestActivity ({ uniqPolicy, lookupCode, action, activityCode }) {
    try {
      const res = await API.graphql({
        query: mutations.changeRequestActivity,
        variables: {
          uniqPolicy, lookupCode, action, activityCode
        }
      })
      return res.data.changeRequestActivity
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getVehicleYears () {
    try {
      const res = await API.graphql({
        query: queries.getVehicleYears
      })
      return res.data.getVehicleYears
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getVehicleMakes ({ year }) {
    try {
      const res = await API.graphql({
        query: queries.getVehicleMakes,
        variables: { year }
      })
      return res.data.getVehicleMakes
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getVehicleModels ({ year, make }) {
    try {
      const res = await API.graphql({
        query: queries.getVehicleModels,
        variables: { year, make }
      })
      return res.data.getVehicleModels
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async createRatesbotSettings ({ payload }) {
    try {
      const res = await API.graphql({
        query: mutations.createRatesbotSettings,
        variables: { payload }
      })
      return res.data.createRatesbotSettings
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async runRatesbotRequestFunction ({ lookupCode, uniqPolicy, data, origin, email }) {
    try {
      const res = await API.graphql({
        query: mutations.runRatesbotRequestFunction,
        variables: { lookupCode, uniqPolicy, data, origin, email }
      })
      return res.data.runRatesbotRequestFunction
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async runPropertyMapperFunction ({ id, origin }) {
    try {
      const res = await API.graphql({
        query: mutations.runPropertyMapperFunction,
        variables: { id, origin }
      })
      return {
        id: res.data.runPropertyMapperFunction.id,
        origin: res.data.runPropertyMapperFunction.origin,
        lookupCode: res.data.runPropertyMapperFunction.lookupCode,
        payload: res.data.runPropertyMapperFunction.payload,
        state: res.data.runPropertyMapperFunction.state,
        errorMessage: res.data.runPropertyMapperFunction.errorMessage
      }
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async insertQuote ({ origin, email, payload }) {
    try {
      const res = await API.graphql({
        query: mutations.insertQuote,
        variables: { origin, email, payload }
      })
      return res.data.insertQuote
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getScheduledCallbacks ({ callbackDate }) {
    try {
      const res = await API.graphql({
        query: queries.getScheduledCallbacks,
        variables: { callbackDate }
      })
      return res.data.getScheduledCallbacks
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPropertyQuote ({ id }) {
    try {
      const res = await API.graphql({
        query: queries.getPropertyQuote,
        variables: { id }
      })
      return res.data.getPropertyQuote
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async runPropertyRequestFunction ({ id, origin }) {
    try {
      const res = await API.graphql({
        query: mutations.runPropertyRequestFunction,
        variables: { id, origin }
      })
      return res
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getRatesbotSettings () {
    try {
      const res = await API.graphql({
        query: queries.getRatesbotSettings
      })
      return res.data.getRatesbotSettings
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getRatesbotObject (uniqPolicy) {
    try {
      const res = await API.graphql({
        query: queries.getRatesbotObject,
        variables: { uniqPolicy }
      })
      return res.data.getRatesbotObject
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getRatesbotObjectByBroker ({ uniqPolicy }) {
    try {
      const res = await API.graphql({
        query: queries.getRatesbotObjectByBroker,
        variables: { uniqPolicy }
      })
      return res.data.getRatesbotObjectByBroker
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async setRatesbotObjectByBroker ({ uniqPolicy, uniqOriginalPolicy, uniqEntity, policyNumber, lookupCode, companyCode, initial }) {
    try {
      const res = await API.graphql({
        query: queries.setRatesbotObjectByBroker,
        variables: { uniqPolicy, uniqOriginalPolicy, uniqEntity, policyNumber, lookupCode, companyCode, initial }
      })
      return res.data.setRatesbotObjectByBroker
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getCaptureUrl () {
    try {
      const res = await API.graphql({
        query: queries.getCaptureUrl
      })
      return JSON.parse(res.data.getCaptureUrl)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getRetrieveUrl ({ token, reference }) {
    try {
      const res = await API.graphql({
        query: queries.getRetrieveUrl,
        variables: {
          token,
          reference
        }
      })
      return JSON.parse(res.data.getRetrieveUrl)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getBinInfo (bin) {
    try {
      const res = await API.graphql({
        query: queries.getBinInfo,
        variables: {
          bin
        }
      })
      return JSON.parse(res.data.getBinInfo)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async deletePciVaultUrl (url) {
    try {
      const res = await API.graphql({
        query: mutations.deletePciVaultUrl,
        variables: { url }
      })
      return res.data.deletePciVaultUrl
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getIcpAdminDashboard () {
    try {
      const res = await API.graphql({
        query: queries.getIcpAdminDashboard
      })
      return JSON.parse(res.data.getIcpAdminDashboard)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getIcpTeamLeadDashboard () {
    try {
      const res = await API.graphql({
        query: queries.getIcpTeamLeadDashboard
      })
      return JSON.parse(res.data.getIcpTeamLeadDashboard)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getIcpBrokerDashboard (email) {
    try {
      const res = await API.graphql({
        query: queries.getIcpBrokerDashboard,
        variables: { email }
      })
      return JSON.parse(res.data.getIcpBrokerDashboard)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getIcpPolicyLog (email) {
    try {
      const res = await API.graphql({
        query: queries.getIcpPolicyLog,
        variables: { email }
      })
      return JSON.parse(res.data.getIcpPolicyLog)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getIcpPolicyBreakdownItems (date) {
    try {
      const limit = 2000
      const countResp = await API.graphql({
        query: queries.getIcpPolicyBreakdownCount,
        variables: { date }
      })
      const count = countResp?.data?.getIcpPolicyBreakdownCount
      if (!count) {
        return []
      }

      const promises = []
      for (let i = 0; i < Math.ceil(count / limit); i++) {
        const offset = i * limit
        promises.push(
          API.graphql({
            query: queries.getIcpPolicyBreakdownItems,
            variables: { date, limit, offset }
          })
        )
      }

      const promiseResp = await Promise.all(promises)
      const computedData = promiseResp.reduce((acc, cur) => {
        if (cur?.data?.getIcpPolicyBreakdownItems) {
          const items = JSON.parse(cur.data.getIcpPolicyBreakdownItems)
          if (Array.isArray(items)) {
            acc.push(...items)
          }
        }
        return acc
      }, [])
      return computedData
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getScoopTeamData () {
    try {
      const res = await API.graphql({
        query: queries.getScoopTeamData
      })
      return JSON.parse(res.data.getScoopTeamData)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTeamDataFile ({ key }) {
    try {
      const res = await API.graphql({
        query: queries.getTeamDataFile,
        variables: { key }
      })
      return res.data.getTeamDataFile
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTeamLeads () {
    try {
      const res = await API.graphql({
        query: queries.getTeamLeads
      })
      return res.data.getTeamLeads
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTeamLeadData ({ email }) {
    try {
      const res = await API.graphql({
        query: queries.getTeamLeadData,
        variables: { email }
      })
      return res.data.getTeamLeadData
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async uploadTeamDataFile (body, key) {
    try {
      const res = await API.graphql({
        query: mutations.uploadTeamDataFile,
        variables: {
          body,
          key
        }
      })
      return res.data.uploadTeamDataFile
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getLobs (lookupCode) {
    try {
      const res = await API.graphql({
        query: queries.getLobs,
        variables: {
          lookupCode
        }
      })
      return JSON.parse(res.data.getLobs)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getContact () {
    try {
      const res = await API.graphql({
        query: queries.getContact
      })
      return res.data.getContact
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getApplicantDetails () {
    try {
      const res = await API.graphql({
        query: queries.getApplicantDetails
      })
      return res.data.getApplicantDetails
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getContacts (lookupCode) {
    try {
      const res = await API.graphql({
        query: queries.getContacts,
        variables: {
          lookupCode
        }
      })
      return JSON.parse(res.data.getContacts)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getUwNotes ({ uniqPolicy, uniqLine }) {
    try {
      const res = await API.graphql({
        query: queries.getUwNotes,
        variables: {
          uniqPolicy,
          uniqLine
        }
      })
      return JSON.parse(res.data.getUwNotes)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getUwNotesProperty ({ uniqPolicy, uniqLine }) {
    try {
      const res = await API.graphql({
        query: queries.getUwNotesProperty,
        variables: {
          uniqPolicy,
          uniqLine
        }
      })
      return JSON.parse(res.data.getUwNotesProperty)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAutoDocumentData ({ uniqPolicy, uniqLine }) {
    try {
      const res = await API.graphql({
        query: queries.getAutoDocumentData,
        variables: {
          uniqPolicy,
          uniqLine
        }
      })
      return JSON.parse(res.data.getAutoDocumentData)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getIrcaDocumentData ({ uniqPolicy, uniqLine }) {
    try {
      const res = await API.graphql({
        query: queries.getIrcaDocumentData,
        variables: {
          uniqPolicy,
          uniqLine
        }
      })
      return JSON.parse(res.data.getIrcaDocumentData)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getPropertyDocumentData ({ uniqPolicy, uniqLine }) {
    try {
      const res = await API.graphql({
        query: queries.getPropertyDocumentData,
        variables: {
          uniqPolicy,
          uniqLine
        }
      })
      return JSON.parse(res.data.getPropertyDocumentData)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getDocuments () {
    try {
      const res = await API.graphql({
        query: queries.getDocuments
      })
      return JSON.parse(res.data.getDocuments)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getDocument ({ documentId }) {
    try {
      const res = await API.graphql({
        query: queries.getDocument,
        variables: {
          documentId
        }
      })
      return JSON.parse(res.data.getDocument)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAdminDocuments ({ from, size, searchQuery, filters }) {
    try {
      const res = await API.graphql({
        query: queries.getAdminDocuments,
        variables: {
          from,
          size,
          searchQuery,
          filters
        }
      })
      return JSON.parse(res.data.getAdminDocuments)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getBrokerDocuments ({ from, size, searchQuery, filters }) {
    try {
      const res = await API.graphql({
        query: queries.getBrokerDocuments,
        variables: {
          from,
          size,
          searchQuery,
          filters
        }
      })
      return JSON.parse(res.data.getBrokerDocuments)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTeamLeadDocuments ({ from, size, searchQuery, filters }) {
    try {
      const res = await API.graphql({
        query: queries.getTeamLeadDocuments,
        variables: {
          from,
          size,
          searchQuery,
          filters
        }
      })
      return JSON.parse(res.data.getTeamLeadDocuments)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getSigninglink (documentId) {
    try {
      const res = await API.graphql({
        query: queries.getSigninglink,
        variables: { documentId }
      })
      return JSON.parse(res.data.getSigninglink)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async generateDocument (documents) {
    try {
      const res = await API.graphql({
        query: mutations.generateDocument,
        variables: {
          documents
        }
      })
      return JSON.parse(res.data.generateDocument)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async sendDocument ({ fields, lookupCode, participants, s3Key }) {
    try {
      const res = await API.graphql({
        query: mutations.sendDocument,
        variables: {
          fields,
          lookupCode,
          participants,
          s3Key
        }
      })
      return res.data.sendDocument
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async uploadSignedDocument (activityId, documentId, lookupCode) {
    try {
      const res = await API.graphql({
        query: mutations.uploadSignedDocument,
        variables: { activityId, documentId, lookupCode }
      })
      return res.data.uploadSignedDocument
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async cancelDocument ({ id, documentId, notifyParticipants, reasonToCancel }) {
    try {
      const res = await API.graphql({
        query: mutations.cancelDocument,
        variables: { id, documentId, notifyParticipants, reasonToCancel }
      })
      return JSON.parse(res.data.cancelDocument)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async sendReminder ({ documentId, reminderType, reminderComment, phone }) {
    try {
      const res = await API.graphql({
        query: mutations.sendReminder,
        variables: { documentId, reminderType, reminderComment, phone }
      })
      return JSON.parse(res.data.sendReminder)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getAdminConfig (config) {
    try {
      const res = await API.graphql({
        query: queries.getAdminConfig,
        variables: {
          config
        }
      })
      return JSON.parse(res.data.getAdminConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getTeamLeadConfig (config) {
    try {
      const res = await API.graphql({
        query: queries.getUserConfig,
        variables: {
          config
        }
      })
      return JSON.parse(res.data.getUserConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getBrokerConfig (config) {
    try {
      const res = await API.graphql({
        query: queries.getBrokerConfig,
        variables: {
          config
        }
      })
      return JSON.parse(res.data.getBrokerConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getUserConfig (config) {
    try {
      const res = await API.graphql({
        query: queries.getUserConfig,
        variables: {
          config
        }
      })
      return JSON.parse(res.data.getUserConfig)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  async getHoursOfOperation (skillId) {
    try {
      const res = await API.graphql({
        query: queries.getHoursOfOperation,
        variables: {
          skillId
        }
      })
      return JSON.parse(res.data.getHoursOfOperation)
    } catch (err) {
      throw this.errorHandler(err) || err
    }
  }

  errorHandler (err) {
    if ((typeof err === 'string' && err.match(/No current user|Cannot retrieve a new session/)) ||
        (typeof err === 'object' && err.code && err.code.match(/NotAuthorizedException/))) {
      // kick to login page
      this.ctx.store.dispatch('auth/logout')
      this.ctx.redirect({ name: `login___${this.ctx.app.i18n.locale}` })
    } else {
      // if err is not associated to session timeout, no user, etc. throw it back up to the caller
      return err
    }
  }
}

export default (context, inject) => {
  const apiService = new ApiService(context)
  context.$api = Vue.prototype.$api = Vue.$api = apiService
  inject('api', apiService)
}
